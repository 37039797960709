import React from "react";
import Portfolio from "./Portfolio";
import Header from "../component/navbar";
const View = () => {
    return (
        <div>
            <Header />
            <Portfolio />
        </div>
    )

}
export default View;