import React from "react"
import { Row, Col } from "reactstrap"
import Logo from "../Assest/image/img/logo.png"
import Fssai from "../../src/Assest/image/img/fssai.png"
import { GrMapLocation } from 'react-icons/gr'
import { FaInstagram } from "react-icons/fa";

const footer = () => {
    return (
        <div className="footer" id="contact">
            <Row>
                <Col lg='12' md='12' className="mt-5 d-flex justify-content-center footer-background" >
                    <Row>
                        <Col lg='12' md='12' className=" pt-3" >
                            <div className="my-2">
                                <p className="text-white">Old No:20, New No:42A,Pillaiyar Kovil Street(Behind BP Bunk, Near Ajitha Stores) 100 ft Rd, Velachery, Chennai-42</p>
                                <a href="tel:9962812344" className="text-white" >Mobile : 9962812344</a>
                                <br />
                                <a href="https://www.google.com/maps/place/12%C2%B058'54.1%22N+80%C2%B013'07.3%22E/@12.9816988,80.2161065,17z/data=!3m1!4b1!4m4!3m3!8m2!3d12.9816988!4d80.2186814?hl=en&entry=ttu" target='_blank' className="text-white text-decoration-underline text-success" >view Location</a><br />
                                <a href="https://www.instagram.com/vaibhavfoodsvelachery?utm_source=qr&igsh=dDlzOGVjejFzM2Nu" className="text-white" target="_blank"><FaInstagram /> Instagram</a><br />
                                <a href="mailto:vaibhavfoods22@gmail.com" className="text-white" >Mail : vaibhavfoods22@gmail.com</a><br />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </div >
    )
}
export default footer