import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom"
import { Collapse, Navbar, NavbarBrand, Nav, NavItem, Container, Offcanvas, OffcanvasHeader, OffcanvasBody, Row, Col } from "reactstrap"
import { FiMenu } from 'react-icons/fi'
import Logo from "../../Assest/image/img/logo.png"
const Header = () => {
    const [collapsed, setCollapsed] = useState(true)
    const toggleNavbar = () => setCollapsed(!collapsed)
    const [canvasOpen, setCanvasOpen] = useState(false)
    const [canvasScroll, setCanvasScroll] = useState(false)
    const [canvasBackdrop, setCanvasBackdrop] = useState(true)

    const toggleCanvasScroll = () => {
        setCanvasScroll(true)
        setCanvasOpen(!canvasOpen)
    }
    const brandName = [
        { id: 1, name: "Vamika Paneer", route: "/vamika" },
        { id: 2, name: "Veeba", route: "/veeba" },
        { id: 3, name: "Thirumala", route: "/thirumala" },
        { id: 4, name: "President", route: "/president" },
        { id: 5, name: "Fresh 2 Go", route: "/fresh2go" },
        { id: 6, name: "Al-halfa", route: "/hafa" },
        { id: 7, name: "CP", route: "/cp" },
        { id: 8, name: "Delicious", route: "/delicious" },
        { id: 9, name: "Mc Cain", route: "/mcain" },
        { id: 10, name: "2m", route: "/2m" },
        { id: 12, name: "Tropolite", route: "/tropolite" },
        { id: 13, name: "ITC Master chef", route: "/itc" },
        { id: 14, name: "ADP'S ZIPPY", route: "/zippy" },
        { id: 15, name: "FRUJOY", route: "/frujoy" },
        { id: 16, name: "Funwave", route: "/funwave" },
        { id: 17, name: "Crest Foods", route: "/crest" },
        { id: 18, name: "Convenio", route: "/convenio" },
        { id: 19, name: "Habit", route: "/habit" },
        { id: 20, name: "Sauces", route: "/sauces" },
        { id: 21, name: "Packing Products", route: "/packingProducts" },
        { id: 22, name: "Frozen Veg Products", route: "/frozenVeg" },
        { id: 23, name: "Bun / Bread", route: "/bun" },
        { id: 24, name: "Nachos", route: "/nachos" },
        { id: 25, name: "Ice Cream", route: "/ice" },
        { id: 26, name: "Paratha Varieties", route: "/paratha" },
        { id: 27, name: "Indian Cheese Products", route: "/indianCheese" },
        { id: 28, name: "Imported Cheese Products", route: "/importedCheese" },
        { id: 29, name: "Tasneem", route: "/tasneem" },
        { id: 30, name: "Manama", route: "/manama" },
        { id: 31, name: "VKL", route: "/vkl" },
        { id: 32, name: "Frozen Fishes", route: "/frozenFishes" },
        { id: 33, name: "AP Frozen Momos", route: "/ap" },
        { id: 34, name: "Mala's Fruit Crushes", route: "/malas" },
        { id: 35, name: "Frozen Chicken / Mutton", route: "/chickenMutton" }
    ]
    return (
        <>
            <Navbar className="py-2 bg-nav " expand="lg">
                <Container className="d-flex justify-content-between">
                    <div className="navbar-collapse d-flex flex-row justify-content-between ">
                        <NavbarBrand>
                            <div>
                                <img src={Logo} alt="logo" width={250} />
                            </div>
                        </NavbarBrand>
                        <button
                            aria-expanded={collapsed}
                            className="navbar-toggler"
                            onClick={toggleNavbar}
                        >
                            <FiMenu color="black" size="24" />
                        </button>
                    </div>
                    <Collapse isOpen={!collapsed} navbar className="position-nav justify-content-end">
                        <div className="d-flex flex-column flex-lg-row justifty-content-start justifty-content-lg-between bg-service p-4 align-items-start align-items-lg-center">
                            <Nav className="nav-link-space d-flex flex-column flex-lg-row justify-content-around">
                                <NavItem className=" mx-3 mt-2 mt-lg-0">
                                    <Link to={`/`} className="header_text nav-text">Home</Link>
                                </NavItem >
                                <NavItem className="mx-3 mt-2 mt-lg-0">
                                    <a href="#" className="header_text nav-text" onClick={toggleCanvasScroll}>Brands</a>
                                </NavItem>
                                <NavItem className="mx-3 mt-2 mt-lg-0">
                                    <a href="#contact" className="header_text nav-text">Contact us</a>
                                </NavItem>
                            </Nav>
                        </div>
                    </Collapse>
                </Container>
            </Navbar>
            <Offcanvas
                scrollable={canvasScroll}
                backdrop={canvasBackdrop}
                direction='end'
                isOpen={canvasOpen}
                toggle={toggleCanvasScroll}
                className="bg-brand"
            >
                <OffcanvasHeader className='canvas-head header_text' toggle={toggleCanvasScroll}><h2>Select the brand</h2></OffcanvasHeader>
                <OffcanvasBody className='mt-3 mx-0 flex-grow-0'>
                    <Row>
                        {brandName && brandName.length > 0 && brandName.map((item) => (
                            <Col lg='6' className="my-2 d-flex justify-content-left">
                                <Link to={item.route} className="text-white"><b>{item.name}</b></Link>
                            </Col>
                        ))}
                    </Row>
                </OffcanvasBody>
            </Offcanvas>
        </>

    )
}
export default Header;