import React from 'react'
import { Row, Col, Container, Badge } from "reactstrap"
import Brouchure from "../Assest/data/brouchreData.json"
import Header from "../component/navbar/index"
import Footer from '../component/footer'
import { FaHandPointRight } from "react-icons/fa"

const paratha = () => {
    const brandData = Brouchure[0].VKL
    return (
        <div >
            <Header />
            <Row className=''>
                <h1 className='text-color mt-2 pt-3'><Badge color='#921013' className='bg-badge'>
                    VKL Seasoning
                </Badge></h1>
                {brandData && brandData.length > 0 && brandData.map((item) => (
                    <Col lg="12" className='d-flex align-items-center justify-content-center  ' key={item.brand_name}>
                        {item.img && item.img.length > 0 && item.img.map((item) => (
                            <Col lg="4" data-aos="fade-right">
                                <img src={require(`../Assest/${item.image}`)} className="img-fluid" width={250} />
                            </Col>
                        ))}
                    </Col>
                ))}
            </Row>
            <Container>
                <Row>
                    {brandData && brandData.length > 0 && brandData.map((item) => (
                        <Col lg="12" className='d-flex justify-content-around '>
                            <div>
                                <Row>
                                    {item.products && item.products.length > 0 && item.products.map((product) => (
                                        <Col xl='4' lg="6" md='6' sm='12' xs='12' className=' mt-1 prod_text mt-1 d-flex justify-content-start  align-items-start' key={product.id}><div className='m-1 text-start' data-aos="zoom-out-right"><FaHandPointRight color='#921013' />{product.name} - {product.weight}</div></Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
export default paratha