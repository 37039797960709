import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom"
import { Row, Col, Button, Container, Card, CardBody, Progress, CardFooter } from "reactstrap"
import Footer from '../component/footer'
import Carousel from 'react-elastic-carousel'
import Resume from "../Assest/resume/resume.pdf"
import Brand1 from "../../src/Assest/image/img/2M.png"
import Brand2 from "../../src/Assest/image/img/ADP.png"
import Brand3 from "../../src/Assest/image/img/al-hafa.png"
import Brand4 from "../../src/Assest/image/img/cavins.png"
import Brand5 from "../../src/Assest/image/img/convenio.png"
import Brand6 from "../../src/Assest/image/img/cp.png"
import Brand7 from "../../src/Assest/image/img/crest.png"
import Brand8 from "../../src/Assest/image/img/delicious.png"
import Brand9 from "../../src/Assest/image/img/fresh2go.png"
import Brand10 from "../../src/Assest/image/img/Frujoy.png"
import Brand11 from "../../src/Assest/image/img/funulave.png"
import Brand12 from "../../src/Assest/image/img/ITC.png"
import Brand14 from "../../src/Assest/image/img/president.png"
import Brand15 from "../../src/Assest/image/img/thirumala.png"
import Brand16 from "../../src/Assest/image/img/Tropolite.png"
import Brand17 from "../../src/Assest/image/img/Mccain.png"
import Brand18 from "../../src/Assest/image/img/VeebaLogo.png"
import Brand19 from "../../src/Assest/image/vamikaImg.png"
import Brand20 from "../../src/Assest/image/img/ManamaLogo.png"
import Brand21 from "../../src/Assest/image/img/MalasLogo.png"
import Banner from "../Assest/image/img/aboutBanner1.png"
import Logo from "../Assest/image/img/logo.png"
import Fssai from "../../src/Assest/image/img/fssai.png"

const Portfolio = () => {
    const brandImg = [
        { id: 1, name: "Vamika Paneer", route: "/vamika", img: Brand19 },
        { id: 2, name: "Veeba", route: "/veeba", img: Brand18 },
        { id: 3, name: "Thirumala", route: "/thirumala", img: Brand15 },
        { id: 4, name: "President", route: "/president", img: Brand14 },
        { id: 5, name: "Fresh 2 Go", route: "/fresh2go", img: Brand9 },
        { id: 6, name: "al-halfa", route: "/hafa", img: Brand3 },
        { id: 7, name: "CP", route: "/cp", img: Brand6 },
        { id: 8, name: "Delicious", route: "/delicious", img: Brand8 },
        { id: 9, name: "Mc Cain", route: "/mcain", img: Brand17 },
        { id: 10, name: "2m", route: "/2m", img: Brand1 },
        { id: 12, name: "Tropolite", route: "/tropolite", img: Brand16 },
        { id: 13, name: "ITC Master chef", route: "/itc", img: Brand12 },
        { id: 14, name: "ADP'S ZIPPY", route: "/zippy", img: Brand2 },
        { id: 15, name: "FRUJOY", route: "/frujoy", img: Brand10 },
        { id: 16, name: "Funwave", route: "/funwave", img: Brand11 },
        { id: 17, name: "Crest Foods", route: "/crest", img: Brand7 },
        { id: 18, name: "Convenio", route: "/convenio", img: Brand5 },
        { id: 19, name: "Manama", route: "/manama", img: Brand20 },
        { id: 20, name: "Malas", route: "/malas", img: Brand21 }
    ]
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
    ];
    return (
        <Fragment>
            <section className="home-section">
                <h2 className="text-center  headingText text-color" data-aos="fade-left">About us</h2>
                <Row className="">
                    <Col lg='12' md='12' sm='12' xs='12' >
                        <div>
                            <img src={Banner} alt='Banner' className="img-fluid" />
                        </div>
                    </Col>
                    {/* <Col lg="6" md="12" className="mt-5 d-flex justify-content-start">
                        <div className="py-3">

                            <h3 className="about-text text-start" data-aos="fade-left"> &nbsp; &nbsp; &nbsp;Vaibhav foods established in 2012 as an experienced and dedicated distributor known for its various HoReCa brands of Gourmet Sauces, Emulsions, Dips, Dessert Toppings, Seasonings, Bakery products, Fruit crushes, Flavour Syrups, Frappe powders, packing materials, Frozen Snacks, Dairy & Sea food products.</h3>
                            <h3 className=" about-text text-start" data-aos="fade-left">
                                &nbsp;&nbsp;&nbsp; We also provide our brand of VAMIKA dairy products ( Paneer,Butter,Fresh Cream and Ghee) from our farm at Namakkal and Erode with great tasting, quality, consistency and hygienic.
                                We established a bench mark in excellence service to Hotels, Restaurants and Caters ( HoReCa )  by prompt delivery.</h3>
                        </div>
                    </Col>
                    <Col lg="6" md="12" className="mt-5 d-flex justify-content-center d-none">
                        <div className="py-3">
                            <h2 className="text-center  headingText text-color" data-aos="fade-left">About us</h2>
                            <h3 className="about-text" data-aos="fade-left">Vaibhav foods established in 2012 as an experienced and dedicated distributor known for its various HoReCa brands of Gourmet Sauces, Emulsions, Dips, Dessert Toppings, Seasonings, Bakery products, Fruit crushes, Flavour Syrups, Frappe powders, packing materials, Frozen Snacks, Dairy & Sea food products.</h3>
                            <h3 className="px-5 about-text" data-aos="fade-left">
                                We also provide our brand of VAMIKA dairy products ( Paneer,Butter,Fresh Cream and Ghee) from our farm at Namakkal and Erode with great tasting, quality, consistency and hygienic.
                                We established a bench mark in excellence service to Hotels, Restaurants and Caters ( HoReCa )  by prompt delivery.</h3>
                        </div>
                    </Col> */}
                </Row>

                <div className="brands-sec mt-5 ">
                    <Container>
                        <h2 className="headingText text-color" data-aos="fade-right">Authorized Distributor</h2>
                        <Row className="d-flex mt-5">
                            <Carousel itemsToShow={4} enableAutoPlay autoPlaySpeed={2000} breakPoints={breakPoints} showIndicators={false}>
                                {brandImg && brandImg.length > 0 && brandImg.map((item) => (

                                    <div className="d-flex" data-aos="fade-left">
                                        <img src={item.img} alt="brands" className="img-fluid" width={75} />
                                    </div>
                                ))}
                            </Carousel>
                        </Row>
                    </Container>
                </div>
                <div className="product-sec mt-5" id="products">
                    <Container>
                        <h2 className="headingText text-color" data-aos="fade-left">Brands</h2>
                        <Row className="mt-5">
                            {brandImg && brandImg.length > 0 && brandImg.map((item) => (
                                <Col lg='3' md="4" sm="12" xs="12" className="d-flex align-items-center justify-content-center my-2">
                                    <Card className="card-img product-card" data-aos="zoom-in">
                                        <CardBody className="d-flex justify-content-center align-items-center">
                                            <div>
                                                <img src={item.img} className="img-fluid" width={100} />
                                            </div>
                                        </CardBody>
                                        <CardFooter className="card-footer my-3">
                                            <Link to={`${item.route}`} className="text-white">View Products</Link>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
                <div className="footer" id="contact">
                    <Footer />
                    {/* <Row>
                        <Col className="mt-5 d-flex justify-content-between footer-background " >
                            <div className="justify-content-start pt-3 pb-3 mx-3" >
                                <img src={Logo} alt="logo" width={150} />
                                <p className="text-white">Old No:20, New No:42A,Pillaiyar Kovil Street(Behind BP Bunk, Near Ajitha Stores) 100 ft Rd, Velachery, Chennai-42</p>
                                <p className="text-white "><a href="tel:9176912344" className="text-color">Cell : 9176912344</a></p>
                                <p className="text-white"><a href="mailto:vaibhavfoods22@gmail.com" className="text-color">Mail: vaibhavfoods22@gmail.com</a></p>
                            </div>
                            <div className="pt-3 mx-3">
                                <img src={Fssai} alt="fssai" width={100} height={100} />
                                <p className="text-white">L.No. 12422002003012</p>
                                <p className="text-white">GST.No 33AACFY4652J1Z0</p>
                            </div>
                        </Col>
                    </Row> */}
                </div>
            </section >
        </Fragment >
    )
}
export default Portfolio;