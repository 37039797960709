import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Home/index"
import Vamika from "../Home/Brands/index"
import Hafa from "../Home/hafa"
import Veeba from "../Home/veeba"
import Convenio from "../Home/convenio"
import Thirumala from "../Home/thirumala"
import Delicious from "../Home/delicious"
import Crest from "../Home/crest"
import ITC from "../Home/Itc"
import Zippy from "../Home/zippy"
import M2 from "../Home/m2"
import CP from "../Home/cp"
import Mcain from "../Home/mcain"
import Fresh2go from "../Home/fresh2go"
import Frujoy from "../Home/frujoy"
import Funwave from "../Home/funwave"
import Tropolite from "../Home/tropolite"
import Habit from "../Home/habit"
import Sauces from "../Home/sauces"
import PackingProducts from "../Home/packingProducts"
import FrozenVeg from "../Home/frozenVeg"
import BunBreads from "../Home/bunBreads"
import Nachos from "../Home/nachos"
import Ice from "../Home/iceCream"
import Paratha from "../Home/paratha"
import IndianCheese from "../Home/indianCheese"
import ImportedCheese from "../Home/importedCheese"
import Tasneem from "../Home/tasneem"
import Manama from "../Home/manama"
import VKL from "../Home/vkl"
import FrozenFishes from "../Home/frozenFishes"
import AP from "../Home/ap"
import President from "../Home/president"
import Malas from "../Home/malas"
import ChickenMutton from "../Home/chickenMutton";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Router = () => {
    React.useEffect(() => {
        AOS.init();
    });
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/vamika" element={<Vamika />} />
                <Route path="/hafa" element={<Hafa />} />
                <Route path="/veeba" element={<Veeba />} />
                <Route path="/convenio" element={<Convenio />} />
                <Route path="/thirumala" element={<Thirumala />} />
                <Route path="/delicious" element={<Delicious />} />
                <Route path="/crest" element={<Crest />} />
                <Route path="/itc" element={<ITC />} />
                <Route path="/zippy" element={<Zippy />} />
                <Route path="/2m" element={<M2 />} />
                <Route path="/cp" element={<CP />} />
                <Route path="/mcain" element={<Mcain />} />
                <Route path="/fresh2go" element={<Fresh2go />} />
                <Route path="/frujoy" element={<Frujoy />} />
                <Route path="/funwave" element={<Funwave />} />
                <Route path="/tropolite" element={<Tropolite />} />
                <Route path="/habit" element={<Habit />} />
                <Route path="/sauces" element={<Sauces />} />
                <Route path="/packingProducts" element={<PackingProducts />} />
                <Route path="/frozenVeg" element={<FrozenVeg />} />
                <Route path="/bun" element={<BunBreads />} />
                <Route path="/nachos" element={<Nachos />} />
                <Route path="/ice" element={<Ice />} />
                <Route path="/paratha" element={<Paratha />} />
                <Route path="/indianCheese" element={<IndianCheese />} />
                <Route path="/importedCheese" element={<ImportedCheese />} />
                <Route path="/tasneem" element={<Tasneem />} />
                <Route path="/manama" element={<Manama />} />
                <Route path="/vkl" element={<VKL />} />
                <Route path="/frozenFishes" element={<FrozenFishes />} />
                <Route path="/ap" element={<AP />} />
                <Route path="/president" element={<President />} />
                <Route path="/malas" element={<Malas />} />
                <Route path="/chickenMutton" element={< ChickenMutton />} />
            </Routes>
        </BrowserRouter>
    )

}
export default Router;