import React from 'react'
import { Row, Col, Badge } from "reactstrap"
import { FaHandPointRight } from "react-icons/fa"
import Footer from '../component/footer'
import Brouchure from "../Assest/data/brouchreData.json"

const Vamika = () => {
    const brandData = Brouchure[0].vamika
    return (
        <div>
            <Row>
                {brandData && brandData.length > 0 && brandData.map((item) => (

                    <Col lg="12" key={item.brand_name}>
                        <h1 className='text-color'><Badge color='#921013' className='bg-badge'>
                            {item.brand_name}
                        </Badge></h1>
                        <div className=' py-5 ' data-aos="fade-right">
                            {item.img && item.img.length > 0 && item.img.map((item) => (
                                <img src={require(`../Assest/${item.image}`)} className="img-fluid" width={250} />
                            ))}
                        </div>
                        <div className=''>

                            {item.products && item.products.length > 0 && item.products.map((product) => (
                                <Col className='prod_text d-flex justify-content-center align-items-center' key={product.id}><div className='m-1' data-aos="zoom-out-right"><FaHandPointRight color='#921013' /> </div>{product.name} - {product.weight}</Col>
                            ))}
                        </div>
                    </Col>
                ))}
            </Row>
            <Footer />
        </div>
    )
}
export default Vamika