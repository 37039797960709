import React from "react";
import Vamika from "../vamika"
import Header from "../../component/navbar/index";
const BrandView = () => {
    return (
        <div>
            <Header />
            <Vamika />
        </div>
    )

}
export default BrandView;